import React from "react";
import { Row, Col, Form, Input, Card, Button } from "antd";
import "./styles.css";
import { useSelector, useDispatch } from "react-redux";
import { numberRegEx } from "../../../Common/Utils";

import {
  setCurrentStep,
  setRideDetails,
  setTripSummary,
} from "../../../../reducers/testRideSlice";
import { TEST_RIDE } from "../../../Common/Constants";

const RiderDetailsForm = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const riderFormRef = React.useRef(null);
  const vehicleDetails = useSelector((state) => state.eol.vehicleDetails);
  const dispatch = useDispatch();
  const rideDetails = useSelector((state) => state.testRide?.rideDetails);

  const onFinish = (value) => {
    dispatch(setRideDetails({ ...rideDetails, ...value }));
    dispatch(setCurrentStep(1));
    dispatch(setTripSummary({}));
  };

  const totalWeightUpdate = () => {
    const riderWeight = riderFormRef.current?.getFieldValue("riderWeight")
      ? Number(riderFormRef.current?.getFieldValue("riderWeight"))
      : 0;
    const extraWeight = riderFormRef.current?.getFieldValue(
      "pillionRiderWeight"
    )
      ? Number(riderFormRef.current?.getFieldValue("pillionRiderWeight"))
      : 0;
    riderFormRef.current?.setFieldsValue({
      totalWeight: vehicleDetails?.weight + riderWeight + extraWeight,
    });
  };

  return (
    <Form
      name="riderForm"
      layout="vertical"
      ref={riderFormRef}
      onFinish={onFinish}
      initialValues={{
        ...rideDetails,
        riderName:
          rideDetails.riderName ||
          userDetails.firstName + " " + userDetails.lastName,
        totalWeight: rideDetails.totalWeight || vehicleDetails?.weight,
      }}
      validateMessages={{
        required: TEST_RIDE.FORM.REQUIRED_FIELDS.BLANK_ERROR,
        whitespace: TEST_RIDE.FORM.REQUIRED_FIELDS.BLANK_ERROR,
      }}
      autoComplete="off"
    >
      <Card bordered={false} className="input-box">
        <Row gutter={16} className="mt1">
          <Col span={24}>
            <Form.Item
              name="riderName"
              label={TEST_RIDE.FORM.REQUIRED_FIELDS.RIDER_NAME}
              rules={[
                {
                  required: true,
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="Rider Name" allowClear size="large" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="riderWeight"
              label={TEST_RIDE.FORM.REQUIRED_FIELDS.RIDER_WEIGHT}
              rules={[
                {
                  required: true,
                },
                {
                  message: "Enter a valid number",
                  validator: (_, value) => {
                    if (
                      value &&
                      (!numberRegEx.test(value) || parseInt(value) > 150)
                    ) {
                      return Promise.reject();
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                placeholder="Rider weight"
                maxLength={3}
                name="riderWeight"
                onChange={totalWeightUpdate}
                allowClear
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="pillionRiderName"
              label={TEST_RIDE.FORM.REQUIRED_FIELDS.PILLION_RIDER_NAME}
            >
              <Input size="large" placeholder="Pillion Rider Name" allowClear />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="pillionRiderWeight"
              label={TEST_RIDE.FORM.REQUIRED_FIELDS.PILLION_RIDER_WEIGHT}
              rules={[
                {
                  message: "Enter a valid number",
                  validator: (_, value) => {
                    if (
                      value &&
                      (!numberRegEx.test(value) || parseInt(value) > 150)
                    ) {
                      return Promise.reject();
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Pillion Rider weight"
                maxLength={3}
                name="pillionRiderWeight"
                onChange={totalWeightUpdate}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="totalWeight"
              label={TEST_RIDE.FORM.REQUIRED_FIELDS.TOTAL_WEIGHT}
            >
              <Input size="large" type="number" readOnly disabled />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Col span={24}>
        <Form.Item
          className="mt2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Row>
            <Button
              type="primary"
              className="submit-btn"
              htmlType="submit"
              style={{ width: "80px", marginLeft: "2rem" }}
            >
              Next
            </Button>
          </Row>
        </Form.Item>
      </Col>
    </Form>
  );
};

export default RiderDetailsForm;
