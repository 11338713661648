
export const BRAND = {
  NAME: "NDS",
};

export const ROUTES = {
  DEFAULT: "/",
  LOGIN: "/login",
  CONFIRM_REGISTRATION: "/registrationConfirm",
  PROFILE: {
    title: "Profile",
    key: "profile",
    link: "/profile",
  },
  REGISTER: {
    title: "Add User",
    key: "addUser",
    link: "/addUser",
  },
  LOGOUT: {
    title: "Logout",
    key: "logout",
    link: "/logout",
  },
  FORGOT_PASSWORD: {
    title: "Forgot Password",
    key: "forgotPassword",
    link: "/forgotPassword",
  },
  CHANGE_PASSWORD: {
    title: "Change Password",
    key: "changePassword",
    link: "/changePassword",
  },
  MOTOR_STATUS: {
    title: "Motor Status",
    key: "motorStatus",
    link: "/motorStatus",
  },
  DRIVER_BEHAVIOUR: {
    title: "Driver Behaviour",
    key: "driverBehaviour",
    link: "/driverBehaviour",
  },
  EOL_TESTS: {
    title: "EOL Tests",
    key: "eolTests",
    link: "/eolTests",
  },
  EOL_STATISTICS: {
    title: "EOL Statistics",
    key: "eolStatistics",
    link: "/eolStatistics",
  },
  TEST_RIDES: {
    title: "Test Rides",
    key: "testRides",
    link: "/testRides",
  },
  FLEET_STATUS: {
    title: "Fleet Status",
    key: "FleetStatus",
    link: "/FleetStatus",
  },
};

export const ROLES = {
  ADMIN: "Admin",
  EXECUTIVE: "Executive",
  FLEET_MANAGER: "Fleet Manager",
  TEST_RIDE_ENGINEER: "Test Ride Engineer",
  TEST_ENGINEER: "Test Engineer",
  RIDER: "Rider",
};

export const ORG_TYPES = {
  MANUFACTURER: "MANUFACTURER",
  B2B_CUSTOMER: "B2BCUSTOMER",
  DEALER: "DEALER",
};

export const UNITS = {
  KM: "km",
  HOURS: "hrs",
  MILLISECONDS: "ms",
  KM_PER_HR: "km/h",
  MILLI_AMPERE: "mA",
  SECONDS: "s",
  MINUTES: "m",
  METER: "m",
  KG: "kg",
  PERCENT: "%",
};

export const BREADCRUMBS = {
  DASHBOARDS: "Dashboards",
};

export const WARNINGS = {
  RIDER_LOGIN: "Cannot login as a Rider"
}

export const FORMS = {
  ADD_USER: {
    REQUIRED_FIELDS: {
      FIRST_NAME: "* First Name",
      LAST_NAME: "* Last Name",
      EMAIL: "* Email",
      PASSWORD: "* Password",
      PHONE: "* Phone",
      ROLE: "* Select a Role",
      SUBMIT: "Add",
      BLANK_ERROR: "Cannot be empty",
      INVALID_ERROR: "Invalid input",
      SUCCESS: "User has been added",
    },
  }
};

export const TEST_RIDE = {
  FORM: {
    REQUIRED_FIELDS: {
      RIDER_NAME: "Rider Name",
      RIDER_WEIGHT: "Rider Weight",
      PILLION_RIDER_NAME: "Pillion Rider Name",
      PILLION_RIDER_WEIGHT: "Pillion Rider Weight",
      TOTAL_WEIGHT: "Total Weight",
      TIME_RANGE: "Test Drive Time Range",
      FROM_LOCATION: "From Location",
      TO_LOCATION: "To Location",
      ROAD_CONDITION: "Road Condition",
      TRAFFIC: "Traffic",
      CLIMATE_CONDITION: "Climate Condition",
      FLYOVERS: "Flyovers",
      DISTANCE: "Distance",
      BATTERY_MANUFACTURER: "Battery Manufacturer",
      BATTERY_VOLTAGE: "Battery Voltage :",
      START_TRIP: "Start of Trip",
      END_TRIP: "End of Trip",
      COMMENTS: "Comments",
      SUBMIT: "Finish",
      BLANK_ERROR: "Cannot be empty",
      INVALID_DATE_ERROR: "Please choose the correct date",
    }
  },
  RIDER_DETAILS: "Rider Details",
  RIDE_DETAILS: "Ride Details",
  VEHICLE_DETAILS: "Vehicle Details",
}

export const DATE_TIME_PICKER = {
  FILTER_LABLE: {
    FIVE_MINS: "Last 5 mins",
    FIFTEEN_MINS: "Last 15 mins",
    THIRTY_MINS: "Last 30 mins",
    TODAY: "Today",
    YESTERDAY: "Yesterday",
    THIS_WEEK: "This Week",
    LAST_WEEK: "Last Week",
    THIS_MONTH: "This Month",
    LAST_MONTH: "Last Month",
  }
}