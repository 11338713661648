import { Col, Row, Tag } from "antd";
import { useSelector } from "react-redux";
import { formatDateAndTime } from "../../../../Common/Utils";
import SYNC from "../../../../../assets/images/icons/sync.svg";
import USER from "../../../../../assets/images/user.svg";

const RideAnalytics = () => {
  const testRide = useSelector((state) => state.testRide);
  const rideDetails = testRide?.tripSummary?.rideForm;

  return (
    <Row gutter={[16, 16]} className="pd05">
      <Col xs={24} sm={24} md={8} lg={7} xl={7} className="box mr1">
        <Row gutter={[16, 16]} justify="center">
          <Col>
            <img src={USER} alt="User" width="60px" />
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center">
          <Col className="title">{rideDetails?.riderName}</Col>
        </Row>
        <Row gutter={[16, 16]} className="mt1">
          <Col className="title">Ride Time: </Col>
          <Col className="value">
            <div>{formatDateAndTime(rideDetails?.startTime)}</div>
            <div>
              <img src={SYNC} alt="Sync" />
            </div>
            <div>{formatDateAndTime(rideDetails?.stopTime)}</div>
          </Col>
        </Row>
        {rideDetails?.rideStartPlaceName && rideDetails?.rideEndPlaceName && (
          <Row gutter={[16, 16]} className="mt1">
            <Col className="title">Location: </Col>
            <Col className="value">
              <div>{rideDetails?.rideStartPlaceName}</div>
              <div>
                <img src={SYNC} alt="Sync" />
              </div>
              <div>{rideDetails?.rideEndPlaceName}</div>
            </Col>
          </Row>
        )}
      </Col>
      <Col xs={24} sm={24} md={14} lg={16} xl={16}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Row gutter={[16, 16]}>
              <Col className="title">Rider Weight:</Col>
              <Col className="value">{rideDetails?.riderWeight}kg</Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col className="title">Total Weight:</Col>
              <Col className="value">{rideDetails?.totalWeight}kg</Col>
            </Row>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Row gutter={[16, 16]}>
              <Col className="title">Pillion Rider Name:</Col>
              <Col className="value">{rideDetails?.pillionRiderName}</Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col className="title">Pillion Rider Weight:</Col>
              <Col className="value">{rideDetails?.pillionRiderWeight}kg</Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={[16, 16]} className="mt1">
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Row gutter={[16, 16]}>
              <Col className="title">Distance:</Col>
              <Col className="value">{rideDetails?.distance}km</Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col className="title">Road Condition:</Col>
              <Col className="value">
                {rideDetails?.roadCondition.map((item, index) => (
                  <Tag key={index}>{item}</Tag>
                ))}
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col className="title">Traffic:</Col>
              <Col className="value">
                {rideDetails?.traffic.map((item, index) => (
                  <Tag key={index}>{item}</Tag>
                ))}
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Row gutter={[16, 16]}>
              <Col className="title">Flyovers:</Col>
              <Col className="value">{rideDetails?.flyoverCount}</Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col className="title">Climate Condition:</Col>
              <Col className="value">
                {rideDetails?.climateCondition.map((item, index) => (
                  <Tag key={index}>{item}</Tag>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={[16, 16]} className="mt1">
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Row gutter={[16, 16]}>
              <Col className="title">Start Voltage:</Col>
              <Col className="value">{rideDetails?.rideStartVoltage}</Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col className="title">Battery Manufacturer:</Col>
              <Col className="value">{rideDetails?.batteryManufacturer}</Col>
            </Row>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Row gutter={[16, 16]}>
              <Col className="title">End Voltage:</Col>
              <Col className="value">{rideDetails?.rideEndVoltage}</Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={4} md={4} lg={4} xl={4} className="title">
                Comments:
              </Col>
              <Col xs={24} sm={20} md={20} lg={20} xl={20} className="value">
                {rideDetails?.comment}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default RideAnalytics;
