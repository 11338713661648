import React, { useState } from "react";
import { Row, Col, Form, Input, Card, Button } from "antd";
import "./styles.css";
import { useSelector, useDispatch } from "react-redux";
import {
  setCurrentStep,
  setRideDetails,
  setRideHistoryFlag,
  setSelectedTab,
  setTestId,
  setTripSummary,
} from "../../../../reducers/testRideSlice";
import { postApis } from "../../../Common/apis";
import { testRideFormSubmitApiUrl } from "../../../Common/endpoints";
import { toast } from "react-toastify";
import { numberRegEx } from "../../../Common/Utils";
import { TEST_RIDE } from "../../../Common/Constants";

const VehicleDetailsForm = () => {
  const vehicleFormRef = React.useRef(null);
  const dispatch = useDispatch();
  const vehicleDetails = useSelector((state) => state.eol.vehicleDetails);
  const rideDetails = useSelector((state) => state.testRide?.rideDetails);
  const authToken = localStorage.getItem("authToken");
  const [finishBtnLoader, setFinishBtnLoader] = useState(false);

  const onBack = () => {
    dispatch(
      setRideDetails({
        ...rideDetails,
        ...vehicleFormRef.current?.getFieldValue(),
      })
    );
    dispatch(setCurrentStep(1));
  };

  const onFinish = async (value) => {
    setFinishBtnLoader(true);
    const { timeRange, ...testRideValues } = rideDetails;
    const body = {
      imei: vehicleDetails.imeiNumber,
      type: "TEST_RIDE",
      ...testRideValues,
      ...value,
    };
    await postApis(testRideFormSubmitApiUrl, body, authToken)
      .then((res) => {
        dispatch(setCurrentStep(0));
        dispatch(setRideDetails({}));
        dispatch(setTestId(res?.data));
        dispatch(setSelectedTab("rideStatistics"));
        dispatch(setTripSummary({}));
        dispatch(setRideHistoryFlag(res?.data))
        setFinishBtnLoader(false);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
        setFinishBtnLoader(false);
      });
  };

  return (
    <Form
      name="vehicleForm"
      layout="vertical"
      ref={vehicleFormRef}
      onFinish={onFinish}
      initialValues={rideDetails}
      validateMessages={{
        required: TEST_RIDE.FORM.REQUIRED_FIELDS.BLANK_ERROR,
        whitespace: TEST_RIDE.FORM.REQUIRED_FIELDS.BLANK_ERROR,
      }}
      autoComplete="off"
    >
      <Card bordered={false} className="input-box">
        <Row gutter={16} className="mt1">
          <Col span={24}>
            <Form.Item name="batteryManufacturer" label={TEST_RIDE.FORM.REQUIRED_FIELDS.BATTERY_MANUFACTURER}>
              <Input
                size="large"
                placeholder="Battery Manufacturer"
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={TEST_RIDE.FORM.REQUIRED_FIELDS.BATTERY_VOLTAGE} className="mb0 lables-title">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="rideStartVoltage"
                    label={TEST_RIDE.FORM.REQUIRED_FIELDS.START_TRIP}
                    rules={[
                      {
                        message: "Enter a valid number",
                        validator: (_, value) => {
                          if (
                            value &&
                            (!numberRegEx.test(value) || parseInt(value) > 100)
                          ) {
                            return Promise.reject();
                          }

                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Start of Trip"
                      maxLength={3}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="rideEndVoltage"
                    label={TEST_RIDE.FORM.REQUIRED_FIELDS.END_TRIP}
                    rules={[
                      {
                        message: "Enter a valid number",
                        validator: (_, value) => {
                          if (
                            value &&
                            (!numberRegEx.test(value) || parseInt(value) > 100)
                          ) {
                            return Promise.reject();
                          }

                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="End of Trip"
                      maxLength={3}
                      allowClear
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="comment" label={TEST_RIDE.FORM.REQUIRED_FIELDS.COMMENTS}>
              <Input.TextArea size="large" showCount maxLength={150} rows={3} />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Col span={24}>
        <Form.Item
          className="mt2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Row>
            <Button
              className="back-btn"
              htmlType="button"
              style={{ width: "80px" }}
              onClick={onBack}
            >
              Back
            </Button>
            <Button
              type="primary"
              className="submit-btn"
              htmlType="submit"
              loading={finishBtnLoader}
              style={{ width: "100px", marginLeft: "2rem" }}
            >
              {TEST_RIDE.FORM.REQUIRED_FIELDS.SUBMIT}
            </Button>
          </Row>
        </Form.Item>
      </Col>
    </Form>
  );
};

export default VehicleDetailsForm;
