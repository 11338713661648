import { Col, Empty, Row, Table } from "antd";
import { useSelector } from "react-redux";
import { UNITS } from "../../../../Common/Constants";
import { formatDateAndTime } from "../../../../Common/Utils";
import Iframe from "../../../../Common/Iframe";

const BatteryPerformance = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const vehicleDetails = useSelector((state) => state.eol.vehicleDetails);
  const tripSummary = useSelector((state) => state.testRide.tripSummary);

  const batteryLineChart = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${tripSummary?.rideForm?.testId}&from=${tripSummary?.rideForm?.startTime}&to=${tripSummary?.rideForm?.stopTime}&panelId=28`;
  const batteryBarChart = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${tripSummary?.rideForm?.testId}&from=${tripSummary?.rideForm?.startTime}&to=${tripSummary?.rideForm?.stopTime}&panelId=29`;
  const batteryBoxChart = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${tripSummary?.rideForm?.testId}&from=${tripSummary?.rideForm?.startTime}&to=${tripSummary?.rideForm?.stopTime}&panelId=30`;
  const batteryVoltageLineChart = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${tripSummary?.rideForm?.testId}&from=${tripSummary?.rideForm?.startTime}&to=${tripSummary?.rideForm?.stopTime}&panelId=31`;
  const batteryVoltageBarChart = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${tripSummary?.rideForm?.testId}&from=${tripSummary?.rideForm?.startTime}&to=${tripSummary?.rideForm?.stopTime}&panelId=32`;
  const batteryVoltageBoxChart = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${tripSummary?.rideForm?.testId}&from=${tripSummary?.rideForm?.startTime}&to=${tripSummary?.rideForm?.stopTime}&panelId=33`;
  const batteryCurrentLineChart = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${tripSummary?.rideForm?.testId}&from=${tripSummary?.rideForm?.startTime}&to=${tripSummary?.rideForm?.stopTime}&panelId=34`;
  const batteryCurrentBarChart = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${tripSummary?.rideForm?.testId}&from=${tripSummary?.rideForm?.startTime}&to=${tripSummary?.rideForm?.stopTime}&panelId=35`;
  const batteryCurrentBoxChart = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${tripSummary?.rideForm?.testId}&from=${tripSummary?.rideForm?.startTime}&to=${tripSummary?.rideForm?.stopTime}&panelId=36`;

  const columns = [
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      render: (startTime) => formatDateAndTime(startTime),
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      render: (endTime) => formatDateAndTime(endTime),
    },
    {
      title: "Start Voltage",
      dataIndex: "startVoltage",
      key: "startVoltage",
    },
    {
      title: "End Voltage",
      dataIndex: "endVoltage",
      key: "endVoltage",
    },
    {
      title: "Starting Charge",
      dataIndex: "startSoc",
      key: "startSoc",
    },
    {
      title: "Ending Charge",
      dataIndex: "endSoc",
      key: "endSoc",
    },
    {
      title: "Distance",
      dataIndex: "distance",
      key: "distance",
    },
  ];

  return (
    <>
      <Row gutter={[16, 16]} className="mt05">
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          Total Distance: {tripSummary?.totalDistance} {UNITS.KM}
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          Total Discharge: {tripSummary?.totalDischarge}
          {UNITS.PERCENT}
        </Col>
      </Row>
      <Row className="mt1">
        <Col span={24}>
          {tripSummary?.batteryPerformance?.length > 0 ? (
            <Table
              columns={columns}
              dataSource={tripSummary?.batteryPerformance}
              rowKey={(record) => record.startTime}
              pagination={false}
              scroll={{ x: "100%" }}
            />
          ) : (
            <Empty />
          )}
        </Col>
      </Row>
      <Row gutter={[12, 12]} className="mt105">
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          {vehicleDetails?.imeiNumber && (
            <Iframe
              title="Battery TimeSeries"
              src={batteryLineChart}
              width="100%"
              height="300"
            />
          )}
        </Col>

        <Col xs={24} sm={24} md={24} lg={7} xl={7}>
          {vehicleDetails?.imeiNumber && (
            <Iframe
              title="Battery Histogram"
              src={batteryBarChart}
              width="100%"
              height="300"
            />
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={7} xl={7}>
          {vehicleDetails?.imeiNumber && (
            <Iframe
              title="Battery Boxplot"
              src={batteryBoxChart}
              width="100%"
              height="300"
            />
          )}
        </Col>
      </Row>
      <Row gutter={[12, 12]} className="mt05">
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          {vehicleDetails?.imeiNumber && (
            <Iframe
              title="Battery Voltage TimeSeries"
              src={batteryVoltageLineChart}
              width="100%"
              height="300"
            />
          )}
        </Col>

        <Col xs={24} sm={24} md={24} lg={7} xl={7}>
          {vehicleDetails?.imeiNumber && (
            <Iframe
              title="Battery Voltage Histogram"
              src={batteryVoltageBarChart}
              width="100%"
              height="300"
            />
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={7} xl={7}>
          {vehicleDetails?.imeiNumber && (
            <Iframe
              title="Battery Voltage Boxplot"
              src={batteryVoltageBoxChart}
              width="100%"
              height="300"
            />
          )}
        </Col>
      </Row>
      <Row gutter={[12, 12]} className="mt05">
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          {vehicleDetails?.imeiNumber && (
            <Iframe
              title="Battery Current TimeSeries"
              src={batteryCurrentLineChart}
              width="100%"
              height="300"
            />
          )}
        </Col>

        <Col xs={24} sm={24} md={24} lg={7} xl={7}>
          {vehicleDetails?.imeiNumber && (
            <Iframe
              title="Battery Current Histogram"
              src={batteryCurrentBarChart}
              width="100%"
              height="300"
            />
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={7} xl={7}>
          {vehicleDetails?.imeiNumber && (
            <Iframe
              title="Battery Current Boxplot"
              src={batteryCurrentBoxChart}
              width="100%"
              height="300"
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default BatteryPerformance;
