import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoggedIn: localStorage.getItem("authToken") !== null,
    roles: [],
  },
  reducers: {
    setLoggedIn: (state) => {
      return {
        ...state,
        isLoggedIn: true,
      };
    },
    setLoggedOut: (state) => {
      return {
        ...state,
        isLoggedIn: false,
      };
    },
    setRoles: (state, action) => {
      return {
        ...state,
        roles: action.payload,
      };
    },
  },
});

export const { setLoggedIn, setLoggedOut, setRoles } = authSlice.actions;
export default authSlice.reducer;
