import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import Iframe from "../../../../Common/Iframe";

const BrakeAnalytics = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const vehicleDetails = useSelector((state) => state.eol.vehicleDetails);
  const tripSummary = useSelector((state) => state.testRide.tripSummary);

  const brakeDuration = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${tripSummary?.rideForm?.testId}&from=${tripSummary?.rideForm?.startTime}&to=${tripSummary?.rideForm?.stopTime}&panelId=8`;
  const brakeDurationFrequency = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${tripSummary?.rideForm?.testId}&from=${tripSummary?.rideForm?.startTime}&to=${tripSummary?.rideForm?.stopTime}&panelId=9`;
  const brakeDurationTrial = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${tripSummary?.rideForm?.testId}&from=${tripSummary?.rideForm?.startTime}&to=${tripSummary?.rideForm?.stopTime}&panelId=19`;
  const brakeDistance = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${tripSummary?.rideForm?.testId}&from=${tripSummary?.rideForm?.startTime}&to=${tripSummary?.rideForm?.stopTime}&panelId=15`;
  const brakeDistanceFrequency = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${tripSummary?.rideForm?.testId}&from=${tripSummary?.rideForm?.startTime}&to=${tripSummary?.rideForm?.stopTime}&panelId=16`;
  const brakeDistanceTrial = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${userDetails?.currentOrganisation?.id}&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${tripSummary?.rideForm?.testId}&from=${tripSummary?.rideForm?.startTime}&to=${tripSummary?.rideForm?.stopTime}&panelId=20`;

  return (
    <>
      <Row gutter={[16, 16]} className="mt05">
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          Total Brakes: {tripSummary?.totalBrakes}
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          Brakes/km: {tripSummary?.brakesPerKm}
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          Avg Distance/Brake: {tripSummary?.averageDistancePerBrake}
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          Avg Duration/Brake: {tripSummary?.averageDurationPerBrake}
        </Col>
      </Row>

      <Row gutter={[12, 12]} className="mt2">
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          {vehicleDetails?.imeiNumber && (
            <Iframe
              title="Brake Duration"
              src={brakeDuration}
              width="100%"
              height="300"
            />
          )}
        </Col>

        <Col xs={24} sm={24} md={24} lg={7} xl={7}>
          {vehicleDetails?.imeiNumber && (
            <Iframe
              title="Brake Duration Frequency"
              src={brakeDurationFrequency}
              width="100%"
              height="300"
            />
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={7} xl={7}>
          {vehicleDetails?.imeiNumber && (
            <Iframe
              title="Brake Duration Boxplot"
              src={brakeDurationTrial}
              width="100%"
              height="300"
            />
          )}
        </Col>
      </Row>

      <Row gutter={[12, 12]} className="mt2">
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          {vehicleDetails?.imeiNumber && (
            <Iframe
              title="Brake Distance"
              src={brakeDistance}
              width="100%"
              height="300"
            />
          )}
        </Col>

        <Col xs={24} sm={24} md={24} lg={7} xl={7}>
          {vehicleDetails?.imeiNumber && (
            <Iframe
              title="Brake Distance Frequency"
              src={brakeDistanceFrequency}
              width="100%"
              height="300"
            />
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={7} xl={7}>
          {vehicleDetails?.imeiNumber && (
            <Iframe
              title="Brake Distance Trial"
              src={brakeDistanceTrial}
              width="100%"
              height="300"
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default BrakeAnalytics;
