import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { UNITS } from "../../../../Common/Constants";
import Iframe from "../../../../Common/Iframe";
import { getGrafanaInterval } from "../../../../Common/Utils";

const SpeedAnalytics = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const vehicleDetails = useSelector((state) => state.eol.vehicleDetails);
  const tripSummary = useSelector((state) => state.testRide.tripSummary);

  const ride_src = `${
    process.env.REACT_APP_GRAFANA_PROXY_BASE_URL
  }/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=12`;
  const frequent_src = `${
    process.env.REACT_APP_GRAFANA_PROXY_BASE_URL
  }/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=13`;
  const speed_src = `${
    process.env.REACT_APP_GRAFANA_PROXY_BASE_URL
  }/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=14`;

  return (
    <>
      <Row gutter={[16, 16]} className="mt05">
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          Average: {tripSummary?.averageSpeed} {UNITS.KM_PER_HR}
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          Statistical Mode: {tripSummary?.statisticalModeOfSpeed}
          {UNITS.KM_PER_HR}
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          Top Value: {tripSummary?.topSpeed} {UNITS.KM_PER_HR}
        </Col>
      </Row>
      <Row gutter={[12, 12]} className="mt2">
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          {vehicleDetails?.imeiNumber && (
            <Iframe
              title="Test ride"
              src={ride_src}
              width="100%"
              height="300"
            />
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={7} xl={7}>
          {vehicleDetails?.imeiNumber && (
            <Iframe
              title="Frequent Speed"
              src={frequent_src}
              width="100%"
              height="300"
            />
          )}
        </Col>
        <Col xs={24} sm={24} md={24} lg={7} xl={7}>
          {vehicleDetails?.imeiNumber && (
            <Iframe title="Speed" src={speed_src} width="100%" height="300" />
          )}
        </Col>
      </Row>
    </>
  );
};

export default SpeedAnalytics;
