import { Col, Row, Select, Card } from "antd";
import { useEffect, useState } from "react";
import { citiesUrl } from "../../Common/endpoints";
import { getApis } from "../../Common/apis";
import "./styles.css";
import Iframe from "../../Common/Iframe";

const FleetStatus = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [fleetList] = useState(["Nichesolv Demo", "LML Fleet"]);
  const [fleetName, setFleetName] = useState("Nichesolv Demo");
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("Bangalore");
  const authToken = localStorage.getItem("authToken");
  const vehiclesMap = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/f06cec50-219b-40d7-8be5-1c68166ddaee/fleet-status?orgId=${userDetails?.currentOrganisation?.id}&var-fleet_name=${fleetName}&var-city=${city}&refresh=5s&panelId=6`;
  const vehicleRunningNo = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/f06cec50-219b-40d7-8be5-1c68166ddaee/fleet-status?orgId=${userDetails?.currentOrganisation?.id}&var-fleet_name=${fleetName}&var-city=${city}&refresh=5s&panelId=2`;
  const vehiclesStoppedNo = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/f06cec50-219b-40d7-8be5-1c68166ddaee/fleet-status?orgId=${userDetails?.currentOrganisation?.id}&var-fleet_name=${fleetName}&var-city=${city}&refresh=5s&panelId=3`;
  const vehiclesIdlingNo = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/f06cec50-219b-40d7-8be5-1c68166ddaee/fleet-status?orgId=${userDetails?.currentOrganisation?.id}&var-fleet_name=${fleetName}&var-city=${city}&refresh=5s&panelId=4`;
  const vehiclesOfflineNo = `${process.env.REACT_APP_GRAFANA_PROXY_BASE_URL}/d-solo/f06cec50-219b-40d7-8be5-1c68166ddaee/fleet-status?orgId=${userDetails?.currentOrganisation?.id}&var-fleet_name=${fleetName}&var-city=${city}&refresh=5s&panelId=5`;

  const onChangeFleet = (value) => {
    setFleetName(value);
  };

  const onChangeCity = (value) => {
    setCity(value);
  };

  const getCities = async () => {
    await getApis(citiesUrl, {}, authToken)
      .then((response) => {
        setCities(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (cities.length === 0) {
      getCities();
    }
  });

  return (
    <Row className="fleet-status-page">
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            {fleetList?.length > 0 && (
              <Select
                showSearch
                value={fleetName || fleetList?.[0]}
                style={{ width: "100%" }}
                onChange={onChangeFleet}
                options={fleetList.map((i) => ({
                  label: i,
                  value: i,
                }))}
              />
            )}
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            {fleetList?.length > 0 && (
              <Select
                showSearch
                value={city}
                style={{ width: "100%" }}
                onChange={onChangeCity}
                options={cities?.map((i) => ({
                  label: i,
                  value: i,
                }))}
              />
            )}
          </Col>
        </Row>

        <Row gutter={[16, 16]} className="mt1">
          <Col xs={24} md={24} lg={6} xl={4}>
            <Row gutter={[16, 16]} align={"center"} wrap className="h100">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Card.Grid className="vehicle-card">
                  <Iframe
                    title="Vehicle Running"
                    src={vehicleRunningNo}
                    width="100%"
                    height="100%"
                  />
                </Card.Grid>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Card.Grid className="vehicle-card">
                  <Iframe
                    title="Vehicle Stopped"
                    src={vehiclesStoppedNo}
                    width="100%"
                    height="100%"
                  />
                </Card.Grid>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Card.Grid className="vehicle-card">
                  <Iframe
                    title="Vehicle Idling"
                    src={vehiclesIdlingNo}
                    width="100%"
                    height="100%"
                  />
                </Card.Grid>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Card.Grid className="vehicle-card">
                  <Iframe
                    title="Vehicle Offline"
                    src={vehiclesOfflineNo}
                    width="100%"
                    height="100%"
                  />
                </Card.Grid>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} lg={18} xl={20}>
            <Iframe
              title="Vehicle Location"
              src={vehiclesMap}
              width="100%"
              height="645"
              className="mh100"
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FleetStatus;
