import React from "react";
import { Row, Col, Form, Input, Select, Card, Radio, Button } from "antd";
import "./styles.css";
import { useSelector, useDispatch } from "react-redux";
import { numberRegEx } from "../../../Common/Utils";
import { setCurrentStep, setRideDetails } from "../../../../reducers/testRideSlice";
import DateTimePicker from "../../../Common/DateTimePicker";
import { TEST_RIDE } from "../../../Common/Constants";

const RideDetailsForm = () => {

  const rideFormRef = React.useRef(null);
  const dispatch = useDispatch();
  const rideDetails = useSelector((state) => state.testRide?.rideDetails);

  const onBack = () => {
    const { climate, level, ...inputValues } =
      rideFormRef.current?.getFieldValue();
    dispatch(
      setRideDetails({
        ...rideDetails,
        ...inputValues,
        climateCondition: climate ? [climate, level] : [],
      })
    );
    dispatch(setCurrentStep(0));
  };

  const onFinish = (value) => {
    const { climate, level, roadCondition, traffic, timeRange, ...inputValues } = value;
    if (timeRange?.[0] < timeRange?.[1]) {
      dispatch(setRideDetails({ ...rideDetails, ...inputValues, timeRange, startTime: Math.floor(timeRange[0]), stopTime: Math.floor(timeRange[1]), roadCondition: roadCondition || [], traffic: traffic || [], climateCondition: climate ? [climate, level] : [] }));
      dispatch(setCurrentStep(2));
    } else {
      rideFormRef?.current?.setFields([
        {
          name: "timeRange",
          value: timeRange,
          errors: [TEST_RIDE.FORM.REQUIRED_FIELDS.INVALID_DATE_ERROR],
        },
      ]);
    }
  };

  const onChangePicker = (value) => {
    rideFormRef?.current?.setFields([
      {
        name: "timeRange",
        value: value,
        errors: value?.[0] >= value?.[1] ? [TEST_RIDE.FORM.REQUIRED_FIELDS.INVALID_DATE_ERROR] : null,
      },
    ]);
  }

  return (
    <Form
      name="rideForm"
      layout="vertical"
      ref={rideFormRef}
      onFinish={onFinish}
      initialValues={{
        ...rideDetails,
        timeRange: rideDetails?.timeRange || [],
        climate: rideDetails.climateCondition?.[0] || null,
        level: rideDetails.climateCondition?.[1] || "Moderate",
      }}
      validateMessages={{
        required: TEST_RIDE.FORM.REQUIRED_FIELDS.BLANK_ERROR,
        whitespace: TEST_RIDE.FORM.REQUIRED_FIELDS.BLANK_ERROR,
      }}
      autoComplete="off"
    >
      <Card bordered={false} className="input-box">
        <Row gutter={16} className="mt1">
          <Col span={24}>
            <Form.Item
              name="timeRange"
              label={TEST_RIDE.FORM.REQUIRED_FIELDS.TIME_RANGE}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DateTimePicker dates={rideDetails?.timeRange || []} onChangePicker={onChangePicker} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="rideStartPlaceName"
              label={TEST_RIDE.FORM.REQUIRED_FIELDS.FROM_LOCATION}
            >
              <Input size="large" placeholder="Start Location" allowClear />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="rideEndPlaceName"
              label={TEST_RIDE.FORM.REQUIRED_FIELDS.TO_LOCATION}
            >
              <Input size="large" placeholder="End Location" allowClear />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="roadCondition"
              label={TEST_RIDE.FORM.REQUIRED_FIELDS.ROAD_CONDITION}
            >
              <Select
                allowClear
                mode="multiple"
                placeholder="Select a Road Condition"
              >
                <Select.Option value="CITY">CITY</Select.Option>
                <Select.Option value="HIGHWAY">HIGHWAY</Select.Option>
                <Select.Option value="VILLAGE">VILLAGE</Select.Option>
                <Select.Option value="OFFROAD">OFF ROAD</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="traffic"
              label={TEST_RIDE.FORM.REQUIRED_FIELDS.TRAFFIC}
            >
              <Select
                allowClear
                size="large"
                mode="multiple"
                placeholder="Select a Traffic"
              >
                <Select.Option value="NO">NO</Select.Option>
                <Select.Option value="LOW">LOW</Select.Option>
                <Select.Option value="MEDIUM">MEDIUM</Select.Option>
                <Select.Option value="HIGH">HIGH</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={TEST_RIDE.FORM.REQUIRED_FIELDS.CLIMATE_CONDITION}
              className="mb0"
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="climate">
                    <Select allowClear size="large" placeholder="Select a Climate">
                      <Select.Option value="Sunny">Sunny</Select.Option>
                      <Select.Option value="Windy">Windy</Select.Option>
                      <Select.Option value="Rainy">Rainy</Select.Option>
                      <Select.Option value="Cold">Cold</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="level">
                    <Radio.Group optionType="button" size="large" style={{ width: "auto" }}>
                      <Radio.Button value="Moderate">Moderate</Radio.Button>
                      <Radio.Button value="High">High</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="flyoverCount"
              label={TEST_RIDE.FORM.REQUIRED_FIELDS.FLYOVERS}
              rules={[
                {
                  message: "Enter a valid number",
                  validator: (_, value) => {
                    if (value && !numberRegEx.test(value)) {
                      return Promise.reject();
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                placeholder="Number of flyovers"
                maxLength={3}
                allowClear
                size="large"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="distance"
              label={TEST_RIDE.FORM.REQUIRED_FIELDS.DISTANCE}
              rules={[
                {
                  message: "Enter a valid number",
                  validator: (_, value) => {
                    if (value && !numberRegEx.test(value)) {
                      return Promise.reject();
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input placeholder="KM" maxLength={3} allowClear size="large" />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Col span={24}>
        <Form.Item
          className="mt2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Row>
            <Button
              className="back-btn"
              htmlType="button"
              style={{ width: "80px" }}
              onClick={onBack}
            >
              Back
            </Button>
            <Button
              type="primary"
              className="submit-btn"
              htmlType="submit"
              style={{ width: "80px", marginLeft: "2rem" }}
            >
              Next
            </Button>
          </Row>
        </Form.Item>
      </Col>
    </Form>
  );
};

export default RideDetailsForm;
