import { Card, Col, Row, Tabs } from "antd";
import { useState } from "react";
import BatteryPerformance from "./Analytics/Battery";
import BrakeAnalytics from "./Analytics/Brake";
import RideAnalytics from "./Analytics/Ride";
import SpeedAnalytics from "./Analytics/Speed";
import Overview from "./Overview";
import SpeedMap from "./SpeedMap";
import TripHighlight from "./TripHighlight";
import BRAKE from "../../../../assets/images/icons/brake.svg";
import SPEED from "../../../../assets/images/icons/speed.svg";
import BATTERY from "../../../../assets/images/icons/battery.svg";
import RIDE from "../../../../assets/images/icons/ride.png";
import VehicleDetails from "../../VehicleDetails";

const RideStatistics = ({ onChangeImei, imei }) => {
  const [activeTab, setActiveTab] = useState("battery");

  const renderIcons = (type) => {
    switch (type) {
      case "battery":
        return (
          <div className="flex-center">
            <img src={BATTERY} alt="Battery" className="icon-size mr05" />{" "}
            Battery Performance
          </div>
        );
      case "speed":
        return (
          <div className="flex-center">
            <img src={SPEED} alt="Speed" className="icon-size mr05" />
            Speed Analytics
          </div>
        );
      case "brake":
        return (
          <div className="flex-center">
            <img src={BRAKE} alt="Brake" className="icon-size mr05" />
            Brake Analytics
          </div>
        );
      case "ride":
        return (
          <div className="flex-center">
            <img src={RIDE} alt="Ride" className="icon-size mr05" />
            Ride Conditions
          </div>
        );
      default:
        return "";
    }
  };

  const items = [
    {
      key: "battery",
      label: renderIcons("battery"),
      children: <BatteryPerformance />,
    },
    {
      key: "speed",
      label: renderIcons("speed"),
      children: <SpeedAnalytics />,
    },
    {
      key: "brake",
      label: renderIcons("brake"),
      children: <BrakeAnalytics />,
    },
    {
      key: "ride",
      label: renderIcons("ride"),
      children: <RideAnalytics />,
    },
  ];

  const onChangeTab = (key) => {
    setActiveTab(key);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
          <VehicleDetails onChangeImei={onChangeImei} imei={imei} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={19} xl={19}>
          <Row>
            <Col span={24}>
              <Overview />
            </Col>
          </Row>
          <Row className="mt1">
            <Col span={24}>
              <TripHighlight />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <SpeedMap />
        </Col>
      </Row>

      <Row className="mt075">
        <Col span={24}>
          <Card>
            <Tabs
              defaultActiveKey={activeTab}
              items={items}
              onChange={onChangeTab}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default RideStatistics;
